import React, { useState, useEffect } from 'react'

import '../Header.scss'
import { Link } from 'gatsby'

const Announcement = ({link}) => {

  return (
   <>
    <div className='announcement-bar  d-flex w-100'>
    <div className='container my-sm-auto d-flex flex-row '>
        <Link
        className='link'
            to={link}
        >Register today to secure your spot in the exclusive FREE webinar on cards migration projects.</Link>
    </div>

    </div>
   </>
  )
}
export default Announcement